<template>
  <div class="text-field text-field_floating">
    <slot></slot>
    <input
      :id="label"
      v-model="text"
      class="text-field__input"
      :type="type"
      :placeholder="label"
      @keyup.enter="emit('action')"
    />
    <label class="text-field__label" :for="label">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
import 'sass/base/input-styled.scss';

import {computed} from '#imports';

const props = defineProps({
  modelValue: {type: String, required: true},
  label: {type: String, required: false},
  type: {type: String, required: false, default: 'search'},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'action'): void;
}>();

const text = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});
</script>
