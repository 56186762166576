import {defineStore} from 'pinia';
import {ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {SEARCH, SEARCH_FAST} from '@/composables/useURL';
import type {IFastSearch} from '@/types/pages/search';

// TODO types
interface IStoreResult {
  results: Record<string, unknown>;
  maxPage: number;
  showMore: Record<string, unknown>;
  tags: {
    name: string;
    count: number;
  }[];
}

export const useSearchStore = defineStore('search', () => {
  const maxPage = ref(1);
  const pageSize = ref(15);
  const isLoading = ref(false);
  const isFetched = ref<boolean>(false);
  const tags = ref<IStoreResult['tags']>([]);
  const items = ref<{
    articles?: Record<string, unknown>[];
    news?: Record<string, unknown>[];
    products?: Record<string, unknown>[];
  }>({});
  const showMore = ref<Record<string, unknown>>({});
  const fastResult = ref<IFastSearch>();

  const getResult = async (query: Record<string, unknown>) => {
    isLoading.value = true;
    items.value = {};
    maxPage.value = 0;
    const response = (await useAuthFetch(SEARCH, {
      query,
    })) as IStoreResult;
    items.value = response.results;
    maxPage.value = response.maxPage;
    showMore.value = response.showMore;
    tags.value = response.tags;

    isLoading.value = false;
    isFetched.value = true;
  };

  const updateResult = async (query: Record<string, unknown>) => {
    isLoading.value = true;

    const {results} = (await useAuthFetch(SEARCH, {
      query,
    })) as IStoreResult;

    if (results.articles?.length > 0) {
      items.value.articles = [...(items.value.articles || []), ...results.articles];
    }
    if (results.news?.length > 0) {
      items.value.news = [...(items.value.news || []), ...results.news];
    }
    if (results.products?.length > 0) {
      items.value.products = [...(items.value.products || []), ...results.products];
    }

    isLoading.value = false;
    isFetched.value = true;
  };

  const getFastResult = async (val: string) => {
    const data = (await useAuthFetch(SEARCH_FAST, {
      query: {text: val},
    })) as IFastSearch;
    fastResult.value = data;
  };

  return {
    maxPage,
    pageSize,
    isLoading,
    isFetched,
    tags,
    items,
    showMore,
    fastResult,

    getResult,
    updateResult,
    getFastResult,
  };
});
