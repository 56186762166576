<template>
  <div v-click-outside="hideFastSearch" class="header__search-wrapper">
    <base-input-styled
      v-model="searchText"
      class="header__search"
      label="Поиск по названию, конфигурации или parts numbers"
      @action="searchPush()"
      @click="showFastSearch()"
    >
      <search-svg id="search_main" @click="searchPush()"></search-svg>
    </base-input-styled>
    <transition>
      <layout-header-main-fast-search
        v-show="isFastSearchShow"
        v-model="searchText"
        @close="hideFastSearch()"
      ></layout-header-main-fast-search>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {computed, navigateTo, ref, watch} from '#imports';
import BaseInputStyled from '@/components/Base/input-styled.vue';
import SearchSvg from 'svg/header/search.vue';
import LayoutHeaderMainFastSearch from './fastSearch/index.vue';

const props = defineProps({
  modelValue: {type: String, required: true},
  disableFastSearch: {type: Boolean, default: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', params: string): void;
}>();

const isFastSearchShow = ref(false);

const searchText = computed<string>({
  get: (): string => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

const showFastSearch = (): void => {
  if (!props.disableFastSearch) {
    isFastSearchShow.value = true;
  }
};
const hideFastSearch = (): void => {
  isFastSearchShow.value = false;
};

watch(searchText, () => {
  if (searchText.value && !props.disableFastSearch) {
    showFastSearch();
  }
});

const searchPush = async () => {
  await navigateTo(`/search?text=${searchText.value}`);
  hideFastSearch();
};
</script>

<style lang="scss" scoped>
.header__search {
  &::after {
    display: none;
  }
}
</style>
