<template>
  <div class="header__search-result">
    <div class="result-search">
      <search-item
        v-if="products?.length"
        title="Товары"
        :items="products"
        :full-url="defaultUrl"
        @close="emit('close')"
      ></search-item>
      <search-item
        v-if="news?.length"
        title="Новости"
        :items="news"
        :full-url="fullUrl('Новости')"
        @close="emit('close')"
      ></search-item>
      <search-item
        v-if="articles?.length"
        title="Статьи"
        :items="articles"
        :full-url="fullUrl('Статьи')"
        @close="emit('close')"
      ></search-item>
      <div v-if="!result" class="result-search__title">По вашему запросу ничего не найдено</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/result-search.sass';

import {computed, watch} from '#imports';
import {debounce} from '@/composables/useUtils';
import {useSearchStore} from '@/stores/search';
import SearchItem from './item.vue';

const props = defineProps({
  modelValue: {type: String, required: true},
});

// TODO no modelValue update?
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const searchStore = useSearchStore();

const result = computed(() => searchStore.fastResult);

const products = computed(() => result.value?.products || []);
const news = computed(() => result.value?.news || []);
const articles = computed(() => result.value?.articles || []);

const reqSearch = async () => {
  await searchStore.getFastResult(props.modelValue);
};

const reqSearchDebounced = debounce(reqSearch, 700);

watch(() => props.modelValue, reqSearchDebounced);

const defaultUrl = computed(() => `/search?text=${props.modelValue}`);
const fullUrl = (tag: string): string => `${defaultUrl.value}&tag=${tag}`;
</script>
